import { useContext } from "react";
import { withTranslation, TFunction } from "react-i18next";
import {
  HeaderSection,
  CustomNavLinkSmall,
  MenuWrapper,
  CTAWrapper,
  Logo,
  Grow,
} from "./styles";
import { RefContext } from "../AppContext";
import { data } from "../../content/data/HeaderData";
import CTAButton from "../../common/CTAButton";
import { scrollTo } from "../../common/func";
import Lightning from "../../content/assets/lightning";
import { Flex } from "../../common/Layouts/styles";
import useIsMobile from "../../common/hooks/useIsMobile";
import Hamburger from "../../content/assets/Hamburger";
import MobileArrow from "../../content/assets/MobileArrow";

const Header = ({ t }: { t: TFunction }) => {
  const isMobile = useIsMobile();

  return (
    <HeaderSection>
      {!isMobile ? <DesktopHeader t={t} /> : <MobileHeader t={t} />}
    </HeaderSection>
  );
};

const DesktopHeader = ({ t }: { t: TFunction }) => {
  const { introBlockRef, contactBlockRef } = useContext(RefContext);

  return (
    <MenuWrapper align="center">
      <Grow justify="center" align="center" grow={1}>
        <MobileArrow />
        <Hamburger />
      </Grow>

      <Grow justify="center" align="center" grow={1}>
        <CustomNavLinkSmall onClick={() => scrollTo(introBlockRef)}>
          <Logo>{t(data.logoTitle)}</Logo>
        </CustomNavLinkSmall>
      </Grow>

      <Grow>
        <CTAWrapper justify="center" grow={1}>
          <CTAButton
            borderRadius="36px"
            padding=".32vw 1vw"
            noArrow={true}
            arrowRotate={-45}
            arrowSize="1.6vw"
            onClick={() => scrollTo(contactBlockRef)}
          >
            <Flex align="center" gap=".6vw">
              <Lightning />
              {data.ctaContact}
            </Flex>
          </CTAButton>
        </CTAWrapper>
      </Grow>
    </MenuWrapper>
  );
};

const MobileHeader = ({ t }: { t: TFunction }) => {
  const { introBlockRef } = useContext(RefContext);

  return (
    <MenuWrapper justify="space-between" align="center" gap="2vw">
      <CustomNavLinkSmall onClick={() => scrollTo(introBlockRef)}>
        <Logo>{t(data.logoTitle)}</Logo>
      </CustomNavLinkSmall>
      <Flex align="center">
        <MobileArrow />
        <Hamburger />
      </Flex>
    </MenuWrapper>
  );
};

export default withTranslation()(Header);
