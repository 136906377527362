import styled from "styled-components";

interface FlexProps {
  justify?: string;
  align?: string;
  gap?: string;
  direction?: string;
  grow?: 0 | 1;
}

export const Flex = styled("div")`
  justify-content: ${(props: FlexProps) => `${props.justify}`};
  align-items: ${(props: FlexProps) => `${props.align}`};
  gap: ${(props: FlexProps) => `${props.gap}`};
  flex-direction: ${(props: FlexProps) => `${props.direction}`};
  flex-grow: ${(props: FlexProps) => `${props.grow}`};
  display: flex;
`

export const Grid = styled("div")`
  display: grid;
`

interface GridContainerTypes {
  cols: string;
  gap?: string;
}

export const GridContainer = styled(Grid)`
  grid-template-columns: ${(props: GridContainerTypes) => props.cols || ""};
  gap: ${(props: GridContainerTypes) => props.gap || ""};
`;