import {
  Dispatch,
  MutableRefObject,
  ReactNode,
  SetStateAction,
  createContext,
  useRef,
  useState,
} from "react";

type BooleanSetState = Dispatch<SetStateAction<boolean>>

export const RefContext = createContext<{
  introBlockRef: MutableRefObject<HTMLDivElement | null> | null;
  competentsBlockRef: MutableRefObject<HTMLDivElement | null> | null;
  goodsBlockRef: MutableRefObject<HTMLDivElement | null> | null;
  teamBlockRef: MutableRefObject<HTMLDivElement | null> | null;
  socialBlockRef: MutableRefObject<HTMLDivElement | null> | null;
  contactBlockRef: MutableRefObject<HTMLDivElement | null> | null;
  blocksRef: MutableRefObject<HTMLDivElement | null> | null;
  competentsBlock: string;
  usBlock: boolean;
  socialBlock: boolean;
  spheresBlock: boolean;
  goodsBlock: boolean;
  contactBlock: boolean;
  setContactBlock: BooleanSetState;
  setCompetentsBlock: Dispatch<SetStateAction<string>>;
  setSpheresBlock: BooleanSetState;
  setGoodsBlock: BooleanSetState;
  setUsBlock: BooleanSetState;
  setSocialBlock: BooleanSetState;
}>({
  introBlockRef: null,
  goodsBlockRef: null,
  competentsBlockRef: null,
  teamBlockRef: null,
  socialBlockRef: null,
  blocksRef: null,
  contactBlockRef: null,
  competentsBlock: "",
  usBlock: false,
  socialBlock: false,
  spheresBlock: false,
  goodsBlock: false,
  contactBlock: true,
  setContactBlock: () => {},
  setCompetentsBlock: () => {},
  setSpheresBlock: () => {},
  setGoodsBlock: () => {},
  setUsBlock: () => {},
  setSocialBlock: () => {},
});

export const RefProvider = ({ children }: { children: ReactNode }) => {
  const introBlockRef = useRef<null | HTMLDivElement>(null);
  const competentsBlockRef = useRef<null | HTMLDivElement>(null);
  const goodsBlockRef = useRef<null | HTMLDivElement>(null);
  const teamBlockRef = useRef<null | HTMLDivElement>(null);
  const socialBlockRef = useRef<null | HTMLDivElement>(null);
  const contactBlockRef = useRef<null | HTMLDivElement>(null);
  const blocksRef = useRef<null | HTMLDivElement>(null);

  const [competentsBlock, setCompetentsBlock] = useState(""); //
  const [spheresBlock, setSpheresBlock] = useState(false);
  const [goodsBlock, setGoodsBlock] = useState(false);
  const [usBlock, setUsBlock] = useState(false);
  const [socialBlock, setSocialBlock] = useState(false);
  const [contactBlock, setContactBlock] = useState(true);

  return (
    <RefContext.Provider
      value={{
        introBlockRef,
        blocksRef,
        competentsBlockRef,
        goodsBlockRef,
        teamBlockRef,
        socialBlockRef,
        contactBlockRef,
        competentsBlock,
        setCompetentsBlock,
        usBlock,
        setUsBlock,
        spheresBlock,
        setSpheresBlock,
        goodsBlock,
        setGoodsBlock,
        socialBlock,
        setSocialBlock,
        contactBlock,
        setContactBlock
      }}
    >
      {children}
    </RefContext.Provider>
  );
};

export const theme = {
  breakpoints: {
    mobile: '500px',
    tablet: '768px',
    desktop: '1024px',
  },
};
