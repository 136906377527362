import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import "antd/dist/reset.css";
import "./index.css";

import Router from "./router";
import i18n from "./translation";
import { RefProvider, theme } from "./components/AppContext";
import { ThemeProvider } from "styled-components";

const App = () => (
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <RefProvider>
        <ThemeProvider theme={theme}>
          <Router />
        </ThemeProvider>
      </RefProvider>
    </I18nextProvider>
  </BrowserRouter>
);

ReactDOM.render(<App />, document.getElementById("root"));
