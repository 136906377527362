import styled, { createGlobalStyle } from "styled-components";

export const primaryColor = "#161618";
export const secondaryColor = "#27272B";
export const thirdColor = "#26292F8A";
export const buttonColor = "#5D65AF";
export const CTAColor = "#00DC75";
export const BrightCTAColor = "#00D764";
export const fadingTextColor = "#888888";
export const grey = "#d6d6d6";

export const Styles = createGlobalStyle`
    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        font-size: .8vw;
        color: white;
        background: ${primaryColor};
        overflow-x: hidden;
    }

    input,
    textarea {
        border-radius: 4px;
        border: 0;
        background: rgb(241, 242, 243);
        color: #CBCBCB;
        transition: all 0.3s ease-in-out;  
        outline: none;
        width: 100%;  
        padding: 1rem 1.25rem;

        :focus-within {
            background: none;
            box-shadow: #2e186a 0px 0px 0px 1px;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: white;
        line-height: 1.2;
        margin-bottom: 0;
        font-weight: 200;
    }

    h1 {
        font-size: 6vw;
    }

    h2 {
        font-size: 2.4vw;
    }
    
    h3 {
        font-size: 1.6vw;
    }

    h4 {
        font-size: 1.2vw;
    }

    h5 {
        font-size: 1vw;
    }

    h6 {
        font-size: .8vw;
    }

    p {
        font-size: 1vw;        
        line-height: 1.6;
        color: #CBCBCB;
    }


    a {
        text-decoration: none;
        outline: none;

        :hover {
            color: #2e186a;
        }
    }
    
    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 1.5rem;
    }

    .ant-drawer-content-wrapper {
        width: 300px !important;
    }
`;

export const AppContainer = styled("div")`
  background-color: ${primaryColor};
  width: 100%;
`;
