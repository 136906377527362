import React from "react";
import { StyledArrow } from "./styles";
import Arrow from "../../content/assets/Arrow";

const ArrowEllipse = ({
  rotate,
  size,
  onClick,
  ellipsePadding
}: {
  rotate?: number;
  size?: string;
  onClick?: () => void;
  ellipsePadding: string;
}) => {
  return (
    <StyledArrow ellipsePadding={ellipsePadding} onClick={onClick}>
      <Arrow rotate={rotate} size={size} />
    </StyledArrow>
  );
};

export default ArrowEllipse;
