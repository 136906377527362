import { ReactNode } from "react";
import { StyledCTAButton, ButtonH4 } from "./styles";
import { CTAColor } from "../../styles/styles";
import ArrowEllipse from "../ArrowEllipse";
import useIsMobile from "../hooks/useIsMobile";

const CTAButton = ({
  children,
  arrowRotate,
  arrowSize = "1.8vw",
  noArrow = false,
  backgroundColor = CTAColor,
  color = "black",
  flexGrow = 0,
  ellipsePadding = "1vw",
  padding = "0 1vw",
  borderRadius = "8px",
  justify = "space-between",
  fontSize = "1vw",
  onClick = () => {},
  style = {},
}: {
  children: ReactNode;
  arrowRotate?: number;
  arrowSize?: string;
  noArrow?: boolean;
  backgroundColor?: string;
  color?: string;
  flexGrow?: 0 | 1;
  ellipsePadding?: string;
  padding?: string;
  borderRadius?: string;
  justify?: string;
  fontSize?: string;
  onClick?: () => void;
  style?: object;
}) => {
  const isMobile = useIsMobile();

  return (
    <StyledCTAButton
      backgroundColor={backgroundColor}
      flexGrow={flexGrow}
      justify={justify}
      padding={!isMobile ? padding : "2.6vw 3vw"}
      onClick={onClick}
      borderRadius={borderRadius}
      {...style}
    >
      <ButtonH4 fontSize={fontSize} color={color}>
        {children}
      </ButtonH4>
      {!noArrow && (
        <ArrowEllipse
          ellipsePadding={ellipsePadding}
          onClick={onClick}
          size={arrowSize}
          rotate={arrowRotate}
        />
      )}
    </StyledCTAButton>
  );
};

export default CTAButton;
