import React from "react";

function Icon({ rotate, size }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 33 33"
      style={{
        rotate: `${rotate}deg`,
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)"
      }}
    >
      <path
        fill="#171719"
        d="M32.355 26.817l-4.491.029V8.236l-24.1 24.099-3.228-3.229L24.635 5.007l-18.58.03V.517h26.3v26.3z"
      ></path>
    </svg>
  );
}

export default Icon;
