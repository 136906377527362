import styled from "styled-components";
import { CTAColor } from "../../styles/styles";

interface ButtonProps {
  backgroundColor: string;
  flexGrow: 0 | 1;
  justify: string;
  padding: string;
  borderRadius: string;
}

export const StyledCTAButton = styled("button")`
  background-color: ${(props: ButtonProps) => props.backgroundColor};
  display: flex;
  justify-content: ${(props: ButtonProps) => props.justify};
  align-items: center;
  border-radius: ${(props: ButtonProps) => props.borderRadius};
  padding: ${(props: ButtonProps) => props.padding};
  flex-grow: ${(props: ButtonProps) => props.flexGrow};
  border: none;
  cursor: pointer;
  z-index: 10;
  gap: 1vw;
  outline: 2px solid ${CTAColor};
`

interface Header4Props {
  color?: string;
  fontSize?: string;
}

export const ButtonH4 = styled("h4")`
  color: ${(props: Header4Props) => props.color} !important;
  font-weight: 400;
  font-size: ${(props: Header4Props) => props.fontSize} !important;
`