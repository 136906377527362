import React from "react";
import useIsMobile from "../../common/hooks/useIsMobile";

function Icon() {
  const isMobile = useIsMobile()
  
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={isMobile ? "7vw" : "2vw"}
      height={isMobile ? "7vw" : "2vw"}
      fill="none"
      viewBox="0 0 22 21"
      style={{bottom: ".15vw", position: "relative"}}
    >
      <path
        fill="#D9D9D9"
        d="M0.879 17.474H17.593V21.492H0.879z"
        transform="rotate(-44.827 .879 17.474)"
      ></path>
      <path
        fill="#D9D9D9"
        d="M12.691 8.56l-5.65-.045L5.618 9.93l-2.833-2.85 2.824-2.807 8.543-.006 2.842 2.859-.058 8.542-2.825 2.807-2.841-2.859 1.425-1.416-.003-5.64z"
      ></path>
    </svg>
  );
}

export default Icon;
