import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1vw"
      height="1vw"
      fill="none"
      viewBox="0 0 19 22"
    >
      <path
        fill="#000"
        d="M18.502 9v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v-2h1v-3h1v-4h-7v-1h1v-1h1v-1h1V9h1V8h1V7h1V6h1V5h1V4h1V3h1V2h1V1h1V0h1v2h-1v3h-1v4h7z"
      ></path>
    </svg>
  );
}

export default Icon;
