import React from "react";
import useIsMobile from "../../common/hooks/useIsMobile";

function Icon() {
  const isMobile = useIsMobile()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={isMobile ? "7vw" : "2.4vw"}
      height={isMobile ? "7vw" : "2.4vw"}
      viewBox="0 0 32 32"
      fill="currentColor"  
      color="white"
    >
      <path d="M6.001 7.128L6 10.438l19.998-.005L26 7.124zm0 14.438L6 24.876l19.998-.006.002-3.308zm0-7.225L6 17.65l19.998-.004.002-3.309z"></path>
    </svg>
  );
}

export default Icon;
