import styled from "styled-components";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import { fadingTextColor, thirdColor } from "../../styles/styles";
import { Flex } from "../../common/Layouts/styles";

export const HeaderSection = styled("header")`
  padding: 1rem 0.5rem;
  position: fixed;
  z-index: 20;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
  padding: 8px;
  overflow: hidden;
  background-color: ${thirdColor};
  width: 40%;

  background: rgba(0, 0, 0, 0.43);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.2px);
  -webkit-backdrop-filter: blur(8.2px);

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 90%;
  }
`;

export const Logo = styled("span")`
  font-size: 1.5em;
  font-family: "Furore", sans-serif;
  white-space: nowrap;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding-right: 0vw;
    border: none;
    font-size: 6em;
  }
`;

export const Index = styled("span")`
  color: ${fadingTextColor};
`;

export const MenuWrapper = styled(Flex)`
  overflow: hidden;
  padding: 0 1vw;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

export const LogoContainer = styled(Link)`
  display: flex;
`;

export const NavLink = styled(Flex)`
  text-align: center;
  gap: 0.6vw;
`;

export const CustomNavLink = styled("div")`
  width: 203px;
  display: inline-block;
  align-items: center;

  @media only screen and (max-width: 411px) {
    width: 150px;
  }

  @media only screen and (max-width: 320px) {
    width: 118px;
  }
`;

export const Burger = styled("div")`
  @media only screen and (max-width: 890px) {
    display: block;
  }

  display: none;

  svg {
    fill: #2e186a;
  }
`;

export const NotHidden = styled("div")`
  @media only screen and (max-width: 890px) {
    display: none;
  }
`;

export const Menu = styled("h5")`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
`;

export const CustomNavLinkSmall = styled(NavLink)`
  padding: 4px 8px;
  position: relative;
  cursor: pointer;
  color: white;
  z-index: 10;
`;

export const CustomNavLinkSmallBlur = styled(NavLink)`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  z-index: 1;
  border-radius: 6px;
`;

export const Label = styled(Flex)`
  font-weight: 500;
  color: #404041;
  text-align: right;
  justify-content: space-between;
  align-items: baseline;
`;

export const Outline = styled(MenuOutlined)`
  font-size: 22px;
`;

export const Span = styled("span")`
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  z-index: 10;
  white-space: nowrap;
`;

export const BorderLeftSpan = styled(Span)`
  border-left: 1px solid #666666;
  padding-left: 1.6vw;
`;

export const BorderRightSpan = styled(Span)`
  border-right: 1px solid #666666;
  padding-right: 1.6vw;
`;
export const CTAWrapper = styled(Flex)`
  font-size: 0.9em !important;
  z-index: 10;
`;

export const Grow = styled(Flex)`
  width: 100%;
`;
